import { Box, CircularProgress, SelectChangeEvent, useMediaQuery, useTheme } from "@mui/material";
import { SyntheticEvent } from "react";
import { useDispatch } from "react-redux";

import { isApiError, useGetFilterOptionsQuery } from "../../api";
import { Tag } from "../../api/types/event";
import { changeAuthor,changeEventType, changeTags } from "../../store/slices/filtersSlice";
import { ErrorMessage } from "../ErrorMessage";
import { MultiSelect } from "../MultiSelect";
import { Select } from "../Select";
import { SortingMenu } from "../SortingMenu/sortingMenu";

export const Filter = () => {
  const { data, isFetching, error } = useGetFilterOptionsQuery();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const dispatch = useDispatch();

  if (isFetching && !data) {
    return (
      <Box
        sx={{
          minHeight: matches ? 206 : 112,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data) return null;

  const handleTagsSelect = (event: SyntheticEvent<Element, Event>, value: Tag[]) => {
    dispatch(changeTags(value));
  };

  const handleTypeSelect = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    const choosenEvent = data.types.find((event) => event.name === value);

    if (!choosenEvent) {
      dispatch(changeEventType());
      return;
    }

    dispatch(changeEventType(choosenEvent));
  };

  const handleAuthorSelect = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    const choosenAuthor = data.authors.find((author) => author.full_name === value);

    if (!choosenAuthor) {
      dispatch(changeAuthor());
      return;
    }

    dispatch(changeAuthor(choosenAuthor));
  };

  return (
    <Box
      sx={{
        display: { lg: "flex", xs: "grid" },
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
        width: "100%",
        alignItems: "center",
        pb: "20px",
        pt: "18px",
        height: "100%",
        pr: { xs: "20px", md: "20px", lg: "0px" },
        pl: { xs: "20px", md: "20px", lg: "0px" },
      }}
    >
      <Select
        defaultValue="All"
        label="Type"
        onChange={handleTypeSelect}
        data={data.types}
        renderValue={(item) => item.name}
        keyExtractor={(item) => item.id}
        valueExtractor={(item) => item.name}
      />
      <MultiSelect
        onChange={handleTagsSelect}
        label="Tags"
        data={data.tags}
        valueExtractor={(item) => item.name}
      />
      <Select
        label="Author"
        defaultValue="Everyone"
        onChange={handleAuthorSelect}
        data={data.authors}
        renderValue={(item) => item.full_name}
        keyExtractor={(item) => item.id.toString()}
        valueExtractor={(item) => item.full_name}
      />
      <SortingMenu />
      {isApiError(error) && <ErrorMessage message={error.data.detail} />}
    </Box>
  );
};
