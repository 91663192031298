import { ButtonClasses, Theme } from "@mui/material";
import { OverridesStyleRules } from "@mui/material/styles/overrides";

import { colors } from "../color";

export const buttonStyles: Partial<
  OverridesStyleRules<keyof ButtonClasses, "MuiButton", Omit<Theme, "components">>
> = {
  root: ({ ownerState }) => ({
    ...(ownerState.variant === "contained" &&
      ownerState.color === "primary" && {
        backgroundColor: colors.neutrals.dark100,
        color: colors.neutrals.gray25,
      }),
  }),
};
