import { Box, Skeleton, Typography } from "@mui/material";

import { Participant as ParticipantModel } from "../../api/types/user";
import { colors } from "../../theme/color";
import { Participant } from "../Participant";

export interface ParticipantsLisProps {
  list?: ParticipantModel[];
  isLoading?: boolean;
}

const participantsSkeleton = (amount: number) => {
  return [...Array(amount)].map((_, i) => (
    <Box key={i} sx={{ marginTop: "12px", display: "flex", gap: "10px" }}>
      <Box>
        <Skeleton variant="circular" width={30} height={30} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Skeleton animation="wave" height={30} variant="text" />
      </Box>
    </Box>
  ));
};

export const ParticipantsList = ({ list = [], isLoading }: ParticipantsLisProps) => {
  const participants = list.map((participant) => {
    return <Participant key={participant.email} participant={participant} />;
  });

  return (
    <Box
      sx={{
        borderRadius: "8px",
        backgroundColor: colors.neutrals.dark100,
        width: { lg: "50%", md: "50%", xs: "100%" },
        height: "478px",
        padding: "30px",
      }}
    >
      <Typography
        component="h3"
        sx={{
          fontSize: "16px",
          color: colors.neutrals.gray100,
          pr: "8px",
          pb: "8px",
        }}
      >
        Participants:
      </Typography>
      <Box sx={{ height: "fill-available", mb: "30px", overflowY: "auto" }}>
        {isLoading ? <Box>{participantsSkeleton(9)}</Box> : participants}
      </Box>
    </Box>
  );
};
