import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, Box, InputAdornment, TextField } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { KeyboardEvent, SyntheticEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { generatePath, Link } from "react-router-dom";

import { useGetEventsQuery } from "../../api";
import { ROUTES } from "../../config";
import { useDebounce } from "../../hooks";
import { changeSearch } from "../../store/slices/filtersSlice";
import { colors } from "../../theme/color";

const inputStyling = {
  "& > fieldset": {
    border: `1px solid ${colors.neutrals.gray25}`,
    color: colors.neutrals.gray25,
  },
};
const listStyling = {
  "& > fieldset": {
    border: `1px solid ${colors.neutrals.gray200}`,
    borderRadius: "6px",
    color: colors.neutrals.white,
  },
};
const listNoBorderStyling = {
  "& > fieldset": {
    border: "unset",
    borderRadius: "6px",
    color: colors.neutrals.white,
  },
};

export const SearchBar = () => {
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const dispatch = useDispatch();

  const onChange = (event: SyntheticEvent<Element, Event>, value: string) => {
    setSearchValue(value === "" ? undefined : value);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.code === "Enter") {
      dispatch(changeSearch(inputValue));
    }
  };

  const { data: events } = useGetEventsQuery({
    search: searchValue,
  });

  const debouncedFunc = useDebounce(onChange, 1000);

  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "0 0 0 16px" },
        ml: { lg: "56px" },
      }}
    >
      <Autocomplete
        loading={inputValue !== searchValue}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search for events"
            onKeyDown={onKeyDown}
            sx={{
              color: colors.neutrals.white,
              height: "40px",
              ".MuiOutlinedInput-root": {
                flexWrap: "nowrap",
                overflow: "hidden",
                backgroundColor: colors.neutrals.dark150,
                ...listNoBorderStyling,
                ":hover": listNoBorderStyling,
                ":focus": listStyling,
                "&.Mui-focused": listStyling,
              },
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: true,
              style: {
                color: colors.neutrals.gray25,
              },
            }}
            InputProps={{
              ...params.InputProps,
              fullWidth: true,
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {
                color: colors.neutrals.gray25,
              },
            }}
          />
        )}
        getOptionLabel={(option) => {
          if (typeof option === "string") return option;

          return option.name;
        }}
        renderOption={(props, option) => (
          <Link
            to={generatePath(ROUTES.eventDetails.detail, {
              eventId: option.id.toString(),
              eventType: option.type.slug,
            })}
            style={{
              textDecoration: "none",
              color: colors.neutrals.dark150,
            }}
            key={nanoid()}
          >
            <Box component="li" {...props}>
              {option.name}
            </Box>
          </Link>
        )}
        options={events ?? []}
        inputValue={inputValue ?? ""}
        onInputChange={(event: SyntheticEvent<Element, Event>, value: string) => {
          debouncedFunc(event, value);
          setInputValue(value === "" ? undefined : value);
        }}
        sx={{
          width: { xs: "100%", lg: "285px", md: "285px" },
          color: colors.neutrals.gray25,
          ".MuiOutlinedInput-root": {
            flexWrap: "nowrap",
            overflow: "hidden",
            ".MuiSvgIcon-root": {
              color: colors.neutrals.gray25,
            },
            ...inputStyling,
            ":hover": inputStyling,
            ":focus": inputStyling,
            "&.Mui-focused": inputStyling,
          },
        }}
      />
    </Box>
  );
};
