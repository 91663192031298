import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";

export interface ErrorMessageProps {
  message: string;
}

export const ErrorMessage = ({ message }: ErrorMessageProps) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={() => setIsOpen(false)}
    >
      <Alert severity="error" variant="filled" onClose={() => setIsOpen(false)}>
        Error: {message}
      </Alert>
    </Snackbar>
  );
};
