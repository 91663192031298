import { BaseQueryFn, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { ROUTES } from "../config";
import { env } from "../env";
import { Attachment, AttachmentType } from "./types/attachment";
import { Event, EventDetails, FilterOptions, Idea, IdeaDetails } from "./types/event";
import { ParticipantLocation } from "./types/user";
import { clearEmptyStrings } from "./utils";

export interface ApiError {
  status: number;
  data: { detail: string };
}

export function isApiError(error: unknown): error is ApiError {
  return (
    typeof error === "object" &&
    error != null &&
    "status" in error &&
    // eslint-disable-next-line
    typeof (error as any).status === "number"
  );
}

const baseQuery = fetchBaseQuery({ baseUrl: env.BASE_API_URL });
const baseQueryWithTokenRefresh: BaseQueryFn = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    const refreshResponse = await fetch(env.TOKEN_REFRESH_URL, {
      method: "POST",
    });
    if (refreshResponse.status === 200) {
      result = await baseQuery(args, api, extraOptions);
    } else {
      if ((window as Window).location.pathname !== ROUTES.login) {
        (window as Window).location = ROUTES.login;
      }
    }
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithTokenRefresh,
  tagTypes: ["User", "Event", "Idea", "FilterOptions"],
  endpoints: (builder) => ({
    getUser: builder.query<User, void>({
      query: () => "/user/",
      providesTags: ["User"],
    }),
    getEvents: builder.query<Event[], EventsQueryArg>({
      query: (arg) => `/events/?${new URLSearchParams(clearEmptyStrings(arg))}`,
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: "Event" as const, id })), "Event"] : ["Event"],
    }),
    getEventsArchive: builder.query<Event[], void>({
      query: () => "/events/archive/",
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: "Event" as const, id })), "Event"] : ["Event"],
    }),
    getEventDetails: builder.query<EventDetails, EventDetailsQueryArg>({
      query: (queryArg) => `/events/${queryArg.type}/${queryArg.id}/`,
      providesTags: (result, error, queryArg) => [
        { type: "Event" as const, id: queryArg.id, slug: queryArg.type },
      ],
    }),
    createEvent: builder.mutation<EventDetails, CreateEventArg>({
      query: (queryArg) => ({
        url: "/events/",
        method: "POST",
        body: queryArg,
      }),
      invalidatesTags: ["Event"],
    }),
    signUpForEvent: builder.mutation<void, SignUpForEventArg>({
      query: (queryArg) => ({
        url: `/events/${queryArg.id}/participate/`,
        method: "POST",
        body: queryArg.request,
      }),
      invalidatesTags: (result, error, queryArg) => [{ type: "Event" as const, id: queryArg.id }],
    }),
    signOutOfEvent: builder.mutation<void, IdQueryArg>({
      query: (queryArg) => ({
        url: `/events/${queryArg.id}/participate/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, queryArg) => [{ type: "Event" as const, id: queryArg.id }],
    }),
    addAttachmentToEvent: builder.mutation<Attachment, AddAttachmentToEventArg>({
      query: (queryArg) => ({
        url: `/events/${queryArg.id}/attachments/`,
        method: "POST",
        body: queryArg.request,
      }),
      invalidatesTags: (result, error, queryArg) => [{ type: "Event" as const, id: queryArg.id }],
    }),
    getIdeas: builder.query<Idea[], void>({
      query: () => "/ideas/",
      providesTags: (result) =>
        result ? [...result.map(({ id }) => ({ type: "Idea" as const, id })), "Idea"] : ["Idea"],
    }),
    getIdeaDetails: builder.query<IdeaDetails, IdQueryArg>({
      query: (queryArg) => `/ideas/${queryArg.id}/`,
      providesTags: (result, error, queryArg) => [{ type: "Idea" as const, id: queryArg.id }],
    }),
    createIdea: builder.mutation<IdeaDetails, CreateIdeaArg>({
      query: (queryArg) => ({
        url: "/ideas/",
        method: "POST",
        body: queryArg,
      }),
      invalidatesTags: ["Idea"],
    }),
    voteForIdea: builder.mutation<void, IdQueryArg>({
      query: (queryArg) => ({
        url: `/ideas/${queryArg.id}/vote/`,
        method: "POST",
      }),
      invalidatesTags: (result, error, queryArg) => [{ type: "Idea" as const, id: queryArg.id }],
    }),
    removeVoteFromIdea: builder.mutation<void, IdQueryArg>({
      query: (queryArg) => ({
        url: `/ideas/${queryArg.id}/vote/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, queryArg) => [{ type: "Idea" as const, id: queryArg.id }],
    }),
    voteForEvent: builder.mutation<void, IdQueryArg>({
      query: (queryArg) => ({
        url: `/events/${queryArg.id}/vote/`,
        method: "POST",
      }),
      invalidatesTags: (result, error, queryArg) => [{ type: "Event" as const, id: queryArg.id }],
    }),
    removeVoteFromEvent: builder.mutation<void, IdQueryArg>({
      query: (queryArg) => ({
        url: `/events/${queryArg.id}/vote/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, queryArg) => [{ type: "Event" as const, id: queryArg.id }],
    }),
    getFilterOptions: builder.query<FilterOptions, void>({
      query: () => "/events/filter_options/",
      providesTags: ["FilterOptions"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetEventsQuery,
  useGetEventsArchiveQuery,
  useGetEventDetailsQuery,
  useCreateEventMutation,
  useSignUpForEventMutation,
  useSignOutOfEventMutation,
  useAddAttachmentToEventMutation,
  useGetIdeasQuery,
  useGetIdeaDetailsQuery,
  useCreateIdeaMutation,
  useVoteForIdeaMutation,
  useVoteForEventMutation,
  useRemoveVoteFromIdeaMutation,
  useRemoveVoteFromEventMutation,
  useGetFilterOptionsQuery,
} = api;

export type User = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  avatar: string;
};

export type EventsQueryArg = {
  type?: string;
  tags?: string;
  speakers?: string;
  search?: string;
  ordering?: string;
};

export type IdQueryArg = {
  id: number | string;
};

export type SignUpForEventArg = IdQueryArg & {
  request: {
    location: ParticipantLocation;
  };
};

export type AddAttachmentToEventArg = IdQueryArg & {
  request: {
    name: string;
    description?: string;
    url: string;
    type: AttachmentType;
  };
};

export type EventDetailsQueryArg = IdQueryArg & {
  type: string;
};

export type CreateEventArg = FormData;

export type CreateIdeaArg = {
  name: string;
  description: string;
};
