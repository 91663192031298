import CoPresentIcon from "@mui/icons-material/CoPresent";
import FolderIcon from "@mui/icons-material/Folder";
import GitHubIcon from "@mui/icons-material/GitHub";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import { Box, Skeleton, Tooltip, Typography } from "@mui/material";

import { Attachment as AttachmentModel } from "../../api/types/attachment";
import { colors } from "../../theme/color";

export interface AttachmentProps {
  attachment: AttachmentModel;
  isLoading?: boolean;
}

const getIconByType = (type: string) =>
  ({
    recording: <MusicVideoIcon />,
    presentation: <CoPresentIcon />,
    repository: <GitHubIcon />,
    other: <FolderIcon />,
  }[type]);

export const Attachment = ({ attachment, isLoading }: AttachmentProps) => {
  const componentIcon = getIconByType(attachment.type.toLowerCase());

  return (
    <Tooltip title={attachment.name}>
      <Box
        component="a"
        target="_blank"
        href={attachment.url}
        sx={{
          display: "flex",
          maxWidth: "100%",
          alignItems: "center",
          justifyContent: "center",
          padding: "4px 32px",
          borderRadius: "8px",
          backgroundColor: colors.neutrals.gray25,
          color: colors.neutrals.dark100,
          border: "1px solid grey",
          mr: "16px",
          mb: "20px",
          textDecoration: "none",
          ":focus": {
            color: "black",
          },
        }}
      >
        {isLoading ? (
          <Box sx={{ height: 54 }}>
            <Skeleton height="100%" />
          </Box>
        ) : (
          <>
            {componentIcon}
            <Typography
              sx={{
                fontSize: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100%",
                pl: "10px",
              }}
            >
              {attachment.name}
            </Typography>
          </>
        )}
      </Box>
    </Tooltip>
  );
};
