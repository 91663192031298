import { Link } from "react-router-dom";

import { ReactComponent as ReactLogo } from "../../assets/icons/Logo.svg";
import { ROUTES } from "../../config";
import { colors } from "../../theme/color";

interface LogoProps {
  height?: string;
  width?: string;
}

export const Logo = ({ height = "48px", width = "48px" }: LogoProps) => {
  return (
    <Link
      to={ROUTES.home}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "50%",
        color: colors.neutrals.gray50,
      }}
    >
      <ReactLogo height={height} width={width} fill={colors.neutrals.gray50} />
    </Link>
  );
};
