import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";
import { Box, Button, Chip, Typography } from "@mui/material";
import { MouseEvent } from "react";
import { generatePath, Link } from "react-router-dom";

import {
  useGetUserQuery,
  useRemoveVoteFromEventMutation,
  useVoteForEventMutation,
} from "../../api";
import { EventPreview as EventPreviewType } from "../../api/types/event";
import defaultCover from "../../assets/default_cover.jpeg";
import { ROUTES } from "../../config";
import { colors } from "../../theme/color";
import { cutSpeaker, cutTags } from "./utils";

export interface EventPreviewProps {
  eventPreview: EventPreviewType;
}

export const EventPreview = ({ eventPreview }: EventPreviewProps) => {
  const { data: user } = useGetUserQuery();
  const [voteForEvent] = useVoteForEventMutation();
  const [removeVoteFromEvent] = useRemoveVoteFromEventMutation();

  const upvotedByMe = eventPreview.votes.some((voter) => voter.user.id === user?.id);

  const vote = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (upvotedByMe) {
      await removeVoteFromEvent({ id: eventPreview.id });
    } else {
      await voteForEvent({ id: eventPreview.id });
    }
  };

  return (
    <Link
      to={generatePath(ROUTES.eventDetails.detail, {
        eventId: eventPreview.id.toString(),
        eventType: eventPreview.type.slug,
      })}
      style={{
        display: "block",
        textDecoration: "none",
        height: "285px",
      }}
    >
      <Box
        sx={{
          height: "172px",
          backgroundImage: `url(${eventPreview.cover ?? defaultCover})`,
          backgroundSize: "cover",
          position: "relative",
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          borderRadius: "8px 8px 0px 0px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            backgroundColor: eventPreview.type.color,
            top: "19px",
            right: "-10px",
            height: "20px",
            width: "max-content",
            border: "unset",
            display: "flex",
            whiteSpace: "nowrap",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "12px",
              color: colors.neutrals.dark200,
              pl: "8px",
              pr: "12px",
            }}
          >
            {eventPreview.type.name}
          </Typography>
          <Box
            sx={{
              width: "5px",
              height: "0",
              borderTop: "10px solid transparent",
              borderBottom: "10px solid transparent",
              borderRight: `10px solid ${colors.neutrals.dark150}`,
            }}
          ></Box>
        </Box>
        <Box
          sx={{
            height: "44px",
            width: "100%",
            background: "rgba(17, 17, 17, 0.8)",
            backdropFilter: "blur(1px)",
            display: "flex",
            pl: "12px",
            alignItems: "center",
          }}
        >
          {cutTags(eventPreview.tags, 30).map((tag) => (
            <Chip key={tag.id} label={tag.name} />
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          height: "112px",
          backgroundColor: colors.neutrals.dark200,
          pl: "12px",
          borderRadius: "0px 0px 8px 8px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "140%",
            color: colors.neutrals.white,
            padding: "12px 0 10px 0",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "95%",
          }}
        >
          {eventPreview.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            pb: "8px",
          }}
        >
          <PersonIcon
            sx={{
              width: "20px",
              color: colors.neutrals.gray50,
            }}
          />
          {cutSpeaker(eventPreview.speakers, 30).map((speaker) => (
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "140%",
                pl: "7px",
                color: colors.neutrals.gray200,
              }}
              key={speaker.id}
            >
              {speaker.full_name}
            </Typography>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CalendarTodayIcon
              sx={{
                width: "20px",
                color: colors.neutrals.gray100,
              }}
            />
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "140%",
                pl: "7px",
                color: colors.neutrals.gray100,
              }}
            >
              {new Date(eventPreview.starts_at).toLocaleDateString()}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              mr: "12px",
              ml: "12px",
            }}
          >
            <Button
              sx={{
                padding: "2px 6px",
                color: colors.neutrals.white,
                minWidth: "0px",
                backgroundColor: upvotedByMe ? colors.primary.green100 : colors.neutrals.dark150,
                fontSize: "12px",
                "&:hover": {
                  backgroundColor: upvotedByMe ? colors.primary.green100 : colors.neutrals.dark150,
                },
              }}
              onClick={vote}
            >
              <StarIcon
                sx={{
                  height: "14px",
                  width: "14px",
                  mr: "4px",
                }}
              />
              {eventPreview.votes.length}
            </Button>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};
