import { Box, Button,Typography } from "@mui/material";
import React, { useCallback } from "react";
import { Link } from "react-router-dom";

import { Logo } from "../../components/Logo";
import { ROUTES } from "../../config";
import { env } from "../../env";
import { colors } from "../../theme/color";

export const Login: React.FC = () => {
  const onLoginClick = useCallback(() => {
    (window as Window).location = env.AUTH_URL;
  }, []);
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: colors.neutrals.dark200,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "fit-content",
            mb: "32px",
          }}
        >
          <Logo height="124px" width="248px" />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ml: "16px",
            }}
          >
            <Link to={ROUTES.home} style={{ textDecoration: "none" }}>
              <Typography
                variant="h6"
                noWrap
                sx={{
                  fontFamily: "Hellix",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "64px",
                  lineHeight: "150%",
                  letterSpacing: "0.08em",
                  color: colors.neutrals.gray50,
                  textDecoration: "none",
                  mt: "20px",
                }}
              >
                enlight
              </Typography>
            </Link>
          </Box>
        </Box>
        <Button
          sx={{
            color: colors.neutrals.dark200,
            textTransform: "unset",
            backgroundColor: colors.neutrals.dark300,
            padding: "10px 32px",
            width: "100%",
            borderRadius: "0px",
            ":hover": {
              backgroundColor: colors.neutrals.dark300,
            },
          }}
          onClick={onLoginClick}
        >
          <Typography
            sx={{
              textTransform: "uppercase",
              color: colors.neutrals.gray50,
              mr: "8px",
            }}
          >
            Log in
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
