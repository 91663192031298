import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { EventDetails } from "../../../../api/types/event";
import defaultCover from "../../../../assets/default_cover.jpeg";
import { cutSpeaker } from "../../../../components/EventPreview/utils";
import { LikeButton } from "../../../../components/LikeButton";
import { colors } from "../../../../theme/color";
import { formatHuman } from "../../../../utils/date";
import { SignUpButton } from "../";

interface MainCardProps {
  eventDetails: EventDetails;
  participating: boolean;
  eventId: string;
}

export const MainCard = ({ eventDetails, participating, eventId }: MainCardProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        backgroundImage: `url(${eventDetails.cover ?? defaultCover})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
        borderRadius: "8px",
        height: "478px",
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          backgroundColor: eventDetails.type.color,
          top: "19px",
          right: "-10px",
          height: "20px",
          width: "max-content",
          border: "unset",
          display: "flex",
          whiteSpace: "nowrap",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "12px",
            color: colors.neutrals.dark200,
            pl: "8px",
            pr: "12px",
          }}
        >
          {eventDetails.type.name}
        </Typography>
        <Box
          sx={{
            width: "5px",
            height: "0",
            borderTop: "10px solid transparent",
            borderBottom: "10px solid transparent",
            borderRight: `10px solid ${colors.neutrals.dark150}`,
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pt: "20px",
          px: "32px",
          flexDirection: "column",
          justifyContent: "flex-end",
          paddingBottom: "12px",
          background: "rgba(17, 17, 17, 0.8)",
          backdropFilter: "blur(1px)",
          borderBottomRightRadius: "8px",
          borderBottomLeftRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <Typography
                component="h3"
                sx={{
                  fontSize: "18px",
                  fontWeight: 600,
                  color: colors.neutrals.white,
                  wordBreak: "break-word",
                  mb: "20px",
                  mr: "10px",
                }}
              >
                {eventDetails.name}
              </Typography>
              <SignUpButton eventId={eventId} participating={participating} />
              <LikeButton eventId={eventId} eventType={eventDetails.type.slug} />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pb: "8px",
                  }}
                >
                  <PersonIcon
                    sx={{
                      width: "20px",
                      color: colors.neutrals.gray50,
                    }}
                  />
                  {cutSpeaker(eventDetails.speakers, 20).map((speaker) => (
                    <Typography
                      key={speaker.id}
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "140%",
                        pl: "7px",
                        color: colors.neutrals.gray100,
                      }}
                    >
                      {speaker.full_name}
                    </Typography>
                  ))}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CalendarTodayIcon
                    sx={{
                      width: "20px",
                      color: colors.neutrals.gray100,
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "140%",
                      pl: "7px",
                      color: colors.neutrals.gray100,
                    }}
                  >
                    {formatHuman(eventDetails.starts_at)}
                  </Typography>
                </Box>
                {matches && (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      mr: "30px",
                      pt: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%",
                      }}
                    >
                      <AccessTimeIcon
                        sx={{
                          width: "20px",
                          color: colors.neutrals.gray100,
                        }}
                      />
                      <Typography
                        component="p"
                        sx={{
                          fontSize: "14px",
                          pl: "7px",

                          color: colors.neutrals.gray100,
                        }}
                      >
                        Created at {formatHuman(eventDetails.created_at)} by{" "}
                        {eventDetails.created_by.full_name}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              {!matches && (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <AccessTimeIcon
                      sx={{
                        width: "20px",
                        color: colors.neutrals.gray100,
                      }}
                    />
                    <Typography
                      component="p"
                      sx={{
                        fontSize: "14px",
                        pl: "7px",

                        color: colors.neutrals.gray100,
                        textAlign: "right",
                      }}
                    >
                      Created at {formatHuman(eventDetails.created_at)} by <br />
                      {eventDetails.created_by.full_name}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
