import CoPresentIcon from "@mui/icons-material/CoPresent";
import FolderIcon from "@mui/icons-material/Folder";
import GitHubIcon from "@mui/icons-material/GitHub";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import { Box, Button, TextField, ToggleButton, ToggleButtonGroup,Typography } from "@mui/material";
import { ChangeEvent, MouseEvent,useCallback, useState } from "react";

import { useAddAttachmentToEventMutation } from "../../api";
import { AttachmentType } from "../../api/types/attachment";

export type AttachmentFormModalProps = {
  eventId: number | string;
  onClose: () => void;
};

export const AttachmentFormModal = ({ onClose, eventId }: AttachmentFormModalProps) => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState<AttachmentType>(AttachmentType.RECORDING);

  const [addAttachment] = useAddAttachmentToEventMutation();

  const onNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    },
    [setName],
  );

  const onUrlChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setUrl(e.target.value);
    },
    [setUrl],
  );

  const onTypeChange = useCallback((e: MouseEvent<HTMLElement>, value: AttachmentType) => {
    setType(value);
  }, []);

  const submit = async () => {
    await addAttachment({
      id: eventId,
      request: {
        type,
        url,
        name,
      },
    }).unwrap();
    onClose();
  };

  return (
    <Box>
      <Box sx={{ marginBottom: "16px" }}>
        <Typography>Choose attachment type:</Typography>
        <ToggleButtonGroup value={type} sx={{ display: "flex" }} exclusive onChange={onTypeChange}>
          <ToggleButton
            value={AttachmentType.RECORDING}
            component="a"
            target="_blank"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px",
              boxShadow: "0 10px 20px 0 rgb(0 0 0 / 10%)",
              borderRadius: "8px",
              backgroundColor: "white",

              color: "black",
              textDecoration: "none",
              textAlign: "center",
              ":focus": {
                color: "black",
              },
            }}
          >
            <MusicVideoIcon />
            <Typography
              sx={{
                fontSize: "8px",
                whiteSpace: "nowrap",
                width: "64px",
              }}
            >
              {AttachmentType.RECORDING}
            </Typography>
          </ToggleButton>
          <ToggleButton
            value={AttachmentType.PRESENTATION}
            component="a"
            target="_blank"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px",
              boxShadow: "0 10px 20px 0 rgb(0 0 0 / 10%)",
              borderRadius: "8px",
              backgroundColor: "white",

              color: "black",
              textDecoration: "none",
              textAlign: "center",
              ":focus": {
                color: "black",
              },
            }}
          >
            <CoPresentIcon />
            <Typography
              sx={{
                fontSize: "8px",
                whiteSpace: "nowrap",
                width: "64px",
              }}
            >
              {AttachmentType.PRESENTATION}
            </Typography>
          </ToggleButton>
          <ToggleButton
            value={AttachmentType.REPOSITORY}
            component="a"
            target="_blank"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px",
              boxShadow: "0 10px 20px 0 rgb(0 0 0 / 10%)",
              borderRadius: "8px",
              backgroundColor: "white",

              color: "black",
              textDecoration: "none",
              textAlign: "center",
              ":focus": {
                color: "black",
              },
            }}
          >
            <GitHubIcon />
            <Typography
              sx={{
                fontSize: "8px",
                whiteSpace: "nowrap",
                width: "64px",
              }}
            >
              {AttachmentType.REPOSITORY}
            </Typography>
          </ToggleButton>
          <ToggleButton
            value={AttachmentType.OTHER}
            component="a"
            target="_blank"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "12px",
              boxShadow: "0 10px 20px 0 rgb(0 0 0 / 10%)",
              borderRadius: "8px",
              backgroundColor: "white",

              color: "black",
              textDecoration: "none",
              textAlign: "center",
              ":focus": {
                color: "black",
              },
            }}
          >
            <FolderIcon />
            <Typography
              sx={{
                fontSize: "8px",
                whiteSpace: "nowrap",
                width: "64px",
              }}
            >
              {AttachmentType.OTHER}
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box sx={{ marginBottom: "16px" }}>
        <Typography>Title:</Typography>
        <TextField fullWidth value={name} onChange={onNameChange} />
      </Box>

      <Box sx={{ marginBottom: "16px" }}>
        <Typography>Url:</Typography>
        <TextField fullWidth value={url} onChange={onUrlChange} />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          onClick={submit}
          sx={{
            backgroundColor: "black",
            color: "white",
            padding: "12px 24px",
            width: "fit-content",
            ":hover": {
              backgroundColor: "black",
              color: "white",
            },
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};
