import { GlobalStyles } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

import { Routes } from "./config";
import { LocalizationProvider } from "./providers/localization";
import { RouterProvider } from "./providers/router";
import { StoreProvider } from "./providers/store";
import { ThemeProvider } from "./providers/theme";
import { globalStyles } from "./theme/global";

function App() {
  return (
    <StoreProvider>
      <RouterProvider>
        <ThemeProvider>
          <LocalizationProvider>
            <GlobalStyles {...globalStyles} />
            <CssBaseline />
            <Routes />
          </LocalizationProvider>
        </ThemeProvider>
      </RouterProvider>
    </StoreProvider>
  );
}

export default App;
