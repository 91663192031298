import { Box, Container, Typography } from "@mui/material";

import { colors } from "../../theme/color";

export const NotFound = () => {
  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h1"
          fontWeight="bold"
          fontFamily="Helix"
          color={colors.neutrals.gray100}
        >
          404
        </Typography>
        <Typography color={colors.neutrals.gray50} fontSize="22px" lineHeight={1.3} maxWidth={600}>
          Sorry, but the page you are looking for may have been moved, deleted or possibly never
          existed.
        </Typography>
      </Box>
    </Container>
  );
};
