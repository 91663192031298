import { Route, Routes as RouterRoutes } from "react-router-dom";

import { About, EventDetails, Home, Login, NotFound,Root } from "../views";
import { ROUTES } from "./routes";

export const Routes = () => {
  return (
    <RouterRoutes>
      <Route element={<Root />}>
        <Route path="*" element={<NotFound />} />
        <Route path={ROUTES.home} element={<Home />} />
        <Route path={ROUTES.about} element={<About />} />
        <Route path={ROUTES.eventDetails.detail} element={<EventDetails />} />
        <Route path={ROUTES.login} element={<Login />} />
      </Route>
    </RouterRoutes>
  );
};
