import coffebreak from "../../assets/coffeebreaks1.png";
import designtalks from "../../assets/designtalks1.png";
import devtalks from "../../assets/devtalks1.png";
import knowledgeshot from "../../assets/knowledgeshots1.png";
import poztechtalks from "../../assets/poztechtalks1.png";
import productchall from "../../assets/productchallenge1.png";
import projectrecap from "../../assets/projectrecap1.png";
import qatalks from "../../assets/qatalks1.png";
import townhall from "../../assets/townhall1.png";

export const eventsData = [
  {
    title: "Coffee breaks",
    desc: "Coffee breaks are internal peer-to-peer presentations on professional and lifestyle topics that take place once a month.",
    imgSrc: coffebreak,
  },
  {
    title: "Knowledge shots",
    desc: "Knowledge shots once a month we train and develop our soft skills with an external guest to be able to collaborate and work as a Team, even better.",
    imgSrc: knowledgeshot,
  },
  {
    title: "DevTalks",
    desc: "DevTalks - meetings dedicated to technology, technical knowledge and technological news. Thanks to these meetings, our developers are up-to-date with the news from the market knowledge.",
    imgSrc: devtalks,
  },
  {
    title: "Project Recap",
    desc: "Project Recap - meetings of project teams (Devs, PMs, QA, Design), during which we get familiar with our colleagues' projects. We learn about the challenges they encountered and the lessons they took out.",
    imgSrc: projectrecap,
  },
  {
    title: "POZTechTalks",
    desc: "POZTechTalks is a video podcast that we created with 2 other Poznań Technology Companies - Bitnoise and STX Next. Our experts share quite a lot of design experience and technical knowledge. A feast for technical people.",
    imgSrc: poztechtalks,
  },
  {
    title: "QATalks",
    desc: "QATalks - during these meetings our employees can develop their testing skills.",
    imgSrc: qatalks,
  },
  {
    title: "Design Talks",
    desc: "DesignTalks - creating products is our speciality, during Design Talks we get to know this process from the perspective of product design specialists.",
    imgSrc: designtalks,
  },
  {
    title: "Town Hall",
    desc: "Town Hall is a monthly meeting where we announce all the triumphs and missteps, promises made and broken to our community, in the view to broaden your opinions, needs and suggestions. At the meeting you can see the presentation from Founding Fathers & Heads & check out the status of the Company's OKR.",
    imgSrc: townhall,
  },
  {
    title: "Product Challenge",
    desc: "Product challenge is a monthly, creative meeting during which participants face a real business challenge. By using a special design framework (5W1H), they are able to design & present a product /solution that can solve a given problem. No matter what your role and department is.",
    imgSrc: productchall,
  },
];
