import StarIcon from "@mui/icons-material/Star";
import { Box, Tooltip, Typography } from "@mui/material";
import { MouseEvent,useMemo } from "react";

import {
  useGetEventDetailsQuery,
  useGetUserQuery,
  useRemoveVoteFromEventMutation,
  useVoteForEventMutation,
} from "../../api";
import { colors } from "../../theme/color";
interface Props {
  eventId: string;
  eventType: string;
}

export const LikeButton = ({ eventId, eventType }: Props) => {
  const { data: user } = useGetUserQuery();
  const { data: eventDetails } = useGetEventDetailsQuery({
    id: eventId,
    type: eventType,
  });

  const [voteForEvent] = useVoteForEventMutation();
  const [removeVoteFromEvent] = useRemoveVoteFromEventMutation();

  const upvotedByMe = useMemo(() => {
    if (!eventDetails || !user) {
      return false;
    }
    return eventDetails.votes.some((voter) => voter.user.id === user.id);
  }, [eventDetails, user]);

  const vote = async (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!eventDetails) {
      return;
    }

    if (upvotedByMe) {
      await removeVoteFromEvent({ id: eventDetails.id }).unwrap();
    } else {
      await voteForEvent({ id: eventDetails.id }).unwrap();
    }
  };

  if (!eventDetails) {
    return null;
  }

  return (
    <Tooltip
      title={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {eventDetails.votes.map((voter) => (
            <Typography key={voter.user.id}>{voter.user.full_name}</Typography>
          ))}
        </Box>
      }
    >
      <Box
        onClick={vote}
        sx={{
          position: "relative",
          cursor: "pointer",
          padding: "8px 12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "6px",
          transition: "all 0.3s ease",
          mb: "20px",
          "&::after": {
            zIndex: -2,
            position: "absolute",
            content: "\"\"",
            display: "block",
            width: "100%",
            height: "100%",
            borderRadius: "6px",
            backgroundColor: upvotedByMe ? colors.primary.green100 : colors.neutrals.dark150,
            transition: "all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)",
            "& svg": {
              transition: "all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)",
            },
            "& path": {
              transition: "all 0.2s ease",
            },
          },
        }}
      >
        <StarIcon
          sx={{
            mr: "8px",
          }}
        />
        <Typography>{eventDetails.votes.length}</Typography>
      </Box>
    </Tooltip>
  );
};
