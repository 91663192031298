import {
  Box,
  FormControl,
  MenuItem,
  Select as SelectMui,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { colors } from "../../theme/color";

const selectStyles = {
  border: `1px solid ${colors.neutrals.gray200}`,
  color: colors.neutrals.white,
};

interface SelectProps<T> {
  data: T[];
  renderValue: (arg: T) => string;
  keyExtractor: (arg: T) => string | number;
  valueExtractor: (arg: T) => string;
  onChange: (event: SelectChangeEvent) => void;
  label: string;
  defaultValue: string;
}

export const Select = <T,>({
  data,
  renderValue,
  keyExtractor,
  valueExtractor,
  onChange,
  label,
  defaultValue,
}: SelectProps<T>) => {
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
    onChange(event);
  };

  const menuItems = data.map((item) => (
    <MenuItem value={valueExtractor(item)} key={keyExtractor(item)}>
      {renderValue(item)}
    </MenuItem>
  ));

  return (
    <Box
      sx={{
        width: "100%",
        flex: 1,
        mr: "20px",
      }}
    >
      <FormControl fullWidth>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "150%",
            color: colors.neutrals.gray50,
            mb: "10px",
          }}
        >
          {label}
        </Typography>
        <SelectMui
          defaultValue={defaultValue}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={handleChange}
          sx={{
            height: "40px",
            color: colors.neutrals.white,
            ".MuiOutlinedInput-notchedOutline": {
              ...selectStyles,
              borderRadius: "6px",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": selectStyles,
            "&:hover .MuiOutlinedInput-notchedOutline": selectStyles,
            ".MuiSvgIcon-root ": {
              fill: `${colors.neutrals.gray50} !important`,
            },
          }}
        >
          <MenuItem value={defaultValue}>{defaultValue}</MenuItem>
          {menuItems}
        </SelectMui>
      </FormControl>
    </Box>
  );
};
