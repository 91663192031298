import { Box, Container, Typography } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";

import { colors } from "../../theme/color";
import { eventsData } from "./data";

export const About = () => {
  return (
    <Container
      disableGutters
      sx={{
        display: "flex",
        pt: "55px",
        pb: "55px",
        justifyContent: "center",
        alignItems: "center",
        pr: { xs: "30px", lg: "0px" },
        pl: { xs: "30px", lg: "0px" },
      }}
    >
      <Box
        display="grid"
        gap={2.5}
        gridTemplateColumns={{
          lg: "1fr 1fr 1fr",
          md: "1fr 1fr",
          xs: "1fr",
        }}
        alignContent="flex-start"
        justifyContent="center"
      >
        {eventsData.map((event) => (
          <Box
            key={nanoid()}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Box
              sx={{
                backgroundSize: "cover",
                backgroundImage: `url(${event.imgSrc})`,
                minHeight: "200px",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                borderRadius: "8px 8px 0px 0px",
              }}
            >
              <Box
                sx={{
                  height: "36px",
                  width: "100%",
                  background:
                    "linear-gradient(255.76deg, rgba(66, 242, 114, 0.75) -56.33%, rgba(66, 242, 114, 0) 124.09%), rgba(255, 222, 0, 0.75)",
                  backdropFilter: "blur(1px)",
                  display: "flex",
                  pl: "12px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "140%",
                    color: colors.neutrals.black,
                  }}
                >
                  {event.title}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "100%",
                backgroundColor: colors.neutrals.dark200,
                p: "25px",
                borderRadius: "0px 0px 8px 8px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "14px",
                  lineHeight: "25px",
                  color: colors.neutrals.white,
                }}
              >
                {event.desc}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Container>
  );
};
