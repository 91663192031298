export const ROUTES = {
  home: "/",
  about: "/about",
  login: "/login",
  eventDetails: {
    index: "/event",
    detail: "/event/:eventType/:eventId",
    archive: "/event/archive",
  },
  notFound: "/404",
};

export const ROUTES_WITH_FILTERS = [ROUTES.home];

export const ROUTES_WITHOUT_NAVBAR = [ROUTES.login];
