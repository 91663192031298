import { Avatar, Box, Typography } from "@mui/material";

import { Participant as ParticipantModel } from "../../api/types/user";
import { colors } from "../../theme/color";

export interface ParticipantProps {
  participant: ParticipantModel;
}

export const Participant = ({ participant }: ParticipantProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        marginTop: "12px",
      }}
    >
      <Avatar
        sx={{ width: "30px", height: "30px", fontSize: "12px" }}
        src={participant.avatar}
      ></Avatar>
      <Typography
        component="p"
        sx={{
          fontSize: "14px",
          color: colors.neutrals.gray25,
        }}
      >
        {participant.email}
      </Typography>
    </Box>
  );
};
