import { Box, Container, Skeleton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { isApiError, useGetEventsQuery } from "../../api";
import { ErrorMessage } from "../../components/ErrorMessage/errorMessage";
import { EventPreview } from "../../components/EventPreview";
import { selectProperFilters } from "../../store/slices/filtersSlice";
import { colors } from "../../theme/color";

const eventPreviewSkeleton = (amount: number) => {
  return [...Array(amount)].map((_, i) => (
    <Skeleton key={i} height={285} variant="rounded" style={{ borderRadius: 8 }} />
  ));
};

export const Home: React.FC = () => {
  const filters = useSelector(selectProperFilters);

  const { data: events, error, isLoading } = useGetEventsQuery(filters);

  const eventsList = events?.map((event) => (
    <Box key={event.id} minWidth={0}>
      <EventPreview key={event.id} eventPreview={event} />
    </Box>
  ));

  return (
    <Box sx={{ backgroundColor: colors.neutrals.dark150 }}>
      <Container disableGutters>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            pt: "30px",
            pb: "30px",
            justifyContent: "center",
            width: "100%",
            pr: { xs: "30px", lg: "0px" },
            pl: { xs: "30px", lg: "0px" },
          }}
        >
          <Box
            display="grid"
            gap={2.5}
            gridTemplateColumns={{
              lg: "1fr 1fr 1fr 1fr",
              md: "1fr 1fr",
              xs: "1fr",
            }}
            alignItems="center"
          >
            {isLoading ? eventPreviewSkeleton(12) : eventsList}
          </Box>
        </Box>
        {isApiError(error) && <ErrorMessage message={error.data.detail} />}
      </Container>
    </Box>
  );
};
