import {
  add,
  addHours,
  addMinutes,
  compareAsc,
  format,
  formatDistance,
  intervalToDuration,
  isValid,
  parseISO,
  sub,
} from "date-fns";

export function formatDistanceFromNow(date: string): string {
  return formatDistance(parseISO(date), Date.now(), { addSuffix: true });
}

export function formatHuman(date: string): string {
  return format(parseISO(date), "dd.MM.yyyy, HH:mm");
}

export function defaultEventTimes() {
  const date = new Date();
  date.setSeconds(0, 0);
  const start = addMinutes(date, 1);
  const end = addHours(start, 1);
  return { start, end };
}

export function moveEndDate(originalStart: Date, originalEnd: Date, newStart: Date): Date {
  const duration = intervalToDuration({
    end: originalStart,
    start: newStart,
  });

  console.log(
    originalStart.toISOString(),
    newStart.toISOString(),
    duration,
    originalEnd.toISOString(),
    add(originalEnd, duration).toISOString(),
  );

  return compareAsc(originalStart, newStart) > 0
    ? sub(originalEnd, duration)
    : add(originalEnd, duration);
}

export function isDateValid(date: Date) {
  return isValid(date);
}
