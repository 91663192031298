import SortIcon from "@mui/icons-material/Sort";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material";
import { MouseEvent,useState } from "react";
import { useDispatch } from "react-redux";

import { changeOrdering } from "../../store/slices/filtersSlice";
import { colors } from "../../theme/color";

export const SortingMenu = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: MouseEvent<HTMLLIElement>) => {
    setAnchorEl(null);
    if (event.currentTarget.dataset.value) {
      dispatch(changeOrdering(event.currentTarget.dataset.value));
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "flex-end",
        alignItems: "flex-start",
      }}
    >
      <Button
        id="basic-button"
        variant="text"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          padding: "0px",
          border: `1px solid ${colors.neutrals.gray200}`,
          minWidth: "0px",
          width: "40px",
          height: "40px",
          ":hover": {
            backgroundColor: colors.neutrals.dark150,
            color: colors.neutrals.gray100,
          },
        }}
      >
        <SortIcon sx={{ color: colors.neutrals.gray100 }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          sx: {
            backgroundColor: colors.neutrals.gray25,
            padding: "0px",
          },
        }}
      >
        <MenuItem
          data-value="-starts_at"
          sx={{
            color: colors.neutrals.dark150,
            width: "285px",
            padding: "8px 12px 12px 8px",
            ":hover": {
              backgroundColor: colors.neutrals.gray100,
            },
          }}
          onClick={handleClose}
        >
          <SortIcon
            sx={{
              mr: "8px",
            }}
          />
          <Typography>Latest</Typography>
        </MenuItem>
        <MenuItem
          data-value="starts_at"
          sx={{
            color: colors.neutrals.dark150,
            width: "285px",
            padding: "8px 12px 12px 8px",
            ":hover": {
              backgroundColor: colors.neutrals.gray100,
            },
          }}
          onClick={handleClose}
        >
          <SortIcon
            sx={{
              mr: "8px",
            }}
          />
          <Typography>Oldest</Typography>
        </MenuItem>
        <MenuItem
          data-value="-votes_count"
          sx={{
            color: colors.neutrals.dark150,
            width: "285px",
            padding: "8px 12px 12px 8px",
            ":hover": {
              backgroundColor: colors.neutrals.gray100,
            },
          }}
          onClick={handleClose}
        >
          <StarBorderIcon
            sx={{
              mr: "8px",
            }}
          />
          <Typography>Popular</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
