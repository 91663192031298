import LogoutIcon from "@mui/icons-material/Logout";
import { Avatar, Box, Button, Tooltip,Typography } from "@mui/material";
import { useCallback } from "react";

import { User } from "../../api";
import { env } from "../../env";
import { colors } from "../../theme/color";
import { getInitials } from "../Participant/utils";

export interface UseShortPannelProps {
  user: User;
}

export const UserShortPannel = ({ user }: UseShortPannelProps) => {
  const onLogout = useCallback(async () => {
    await fetch(env.LOGOUT_URL);
    window.location.reload();
  }, []);

  return (
    <Box
      sx={{
        ml: { lg: "16px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant={"caption"}
          sx={{
            color: colors.neutrals.gray25,
            fontSize: "18px",
          }}
        >
          Hi, {user.first_name}!
        </Typography>
        <Tooltip
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                backgroundColor: colors.neutrals.gray25,
              },
            },
          }}
          title={
            <Button
              sx={{
                color: colors.neutrals.dark200,
                textTransform: "unset",
                ":hover": {
                  backgroundColor: colors.neutrals.gray25,
                },
              }}
              onClick={onLogout}
            >
              <LogoutIcon sx={{ width: "12px", mr: "9px" }} />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontFamily: "Hellix",
                }}
              >
                Log out
              </Typography>
            </Button>
          }
        >
          <Avatar
            sx={{
              ml: 1,
              width: "40px",
              height: "40px",
              fontSize: "8px",
            }}
            src={user.avatar}
          >
            {getInitials(user.first_name, user.last_name)}
          </Avatar>
        </Tooltip>
      </Box>
    </Box>
  );
};
