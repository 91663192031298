import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { EventsQueryArg } from "../../api";
import { EventType, Tag } from "../../api/types/event";
import { Author } from "../../api/types/user";
import { RootState } from "../store";

interface FiltersState {
  eventType: EventType | undefined;
  tags: Tag[];
  author: Author | undefined;
  search: string | undefined;
  ordering: string | undefined;
}

const initialState: FiltersState = {
  eventType: undefined,
  tags: [],
  author: undefined,
  search: undefined,
  ordering: undefined,
};

export const filtersSlice = createSlice({
  name: "filtersSlice",
  initialState: initialState,
  reducers: {
    changeEventType: (state, action: PayloadAction<EventType | undefined>) => {
      return {
        ...state,
        eventType: action.payload,
      };
    },
    changeSearch: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        search: action.payload,
      };
    },
    changeTags: (state, action: PayloadAction<Tag[]>) => {
      return {
        ...state,
        tags: action.payload,
      };
    },
    changeAuthor: (state, action: PayloadAction<Author | undefined>) => {
      return {
        ...state,
        author: action.payload,
      };
    },
    changeOrdering: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        ordering: action.payload,
      };
    },
  },
});

export const selectProperFilters = (state: RootState): EventsQueryArg => {
  const filters = state.filters;

  return {
    tags: filters.tags.map((tag) => tag.id.toString()).join(","),
    speakers: filters.author === undefined ? "" : filters.author.id.toString(),
    type: filters.eventType === undefined ? "" : filters.eventType.id.toString(),
    search: filters.search === undefined ? "" : filters.search,
    ordering: filters.ordering === undefined ? "" : filters.ordering,
  };
};

export const { changeEventType, changeAuthor, changeTags, changeSearch, changeOrdering } =
  filtersSlice.actions;
