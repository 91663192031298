import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  Toolbar,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { useGetUserQuery } from "../../api";
import { ROUTES } from "../../config";
import { ROUTES_WITH_FILTERS } from "../../config/routes";
import { env } from "../../env";
import { colors } from "../../theme/color";
import { Filter } from "../Filter";
import { Logo } from "../Logo";
import { SearchBar } from "../SearchBar";
import { UserShortPannel } from "../UserShortPannel";

export const Navbar = () => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();
  const onLogout = useCallback(async () => {
    await fetch(env.LOGOUT_URL);
    window.location.reload();
  }, []);

  const { data: user } = useGetUserQuery();

  const shouldDisplayFilters = ROUTES_WITH_FILTERS.some((route) => route === pathname);

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: colors.neutrals.dark200,
        boxShadow: "0 10px 20px 0 rgb(0 0 0 / 4%)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        disableGutters
        sx={{
          display: "flex",
          height: "100px",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: { xs: "100%", lg: "unset", md: "unset" },
            }}
          >
            <Logo width="40px" height="40px" />
            <Link to={ROUTES.home} style={{ textDecoration: "none" }}>
              <Typography
                noWrap
                sx={{
                  ml: 1,
                  display: { xs: "none", md: "flex" },
                  width: "100%",
                  fontFamily: "Hellix",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: "24px",
                  lineHeight: "127px",
                  letterSpacing: "0.08em",
                  color: colors.neutrals.gray25,
                  textDecoration: "none",
                }}
              >
                enlight
              </Typography>
            </Link>
            <SearchBar />
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                justifyContent: "center",
                color: colors.neutrals.gray50,
                width: "50%",
              }}
              onClick={() => setOpen(true)}
            >
              <MenuIcon
                sx={{
                  height: "40px",
                  width: "40px",
                }}
              />
            </Box>
            <Drawer
              open={open}
              anchor={"right"}
              onClose={() => setOpen(false)}
              PaperProps={{
                sx: {
                  backgroundColor: colors.neutrals.gray200,
                },
              }}
            >
              {user && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    padding: "16px",
                  }}
                >
                  <UserShortPannel user={user} />
                  <Divider
                    sx={{
                      backgroundColor: colors.neutrals.gray25,
                      mt: "8px",
                      mb: "8px",
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Link to={ROUTES.about} style={{ display: "flex", textDecoration: "none" }}>
                      <Typography
                        variant="h6"
                        noWrap
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "18px",
                          fontWeight: 500,
                          color: colors.neutrals.gray25,
                          fontFamily: "Inter",
                          textDecoration: "none",
                        }}
                      >
                        About
                      </Typography>
                    </Link>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Divider
                      sx={{
                        backgroundColor: colors.neutrals.gray25,
                        mt: "8px",
                        mb: "8px",
                      }}
                    />
                    <Button
                      sx={{
                        color: colors.neutrals.dark200,
                        textTransform: "unset",
                        backgroundColor: colors.neutrals.gray25,
                        ":hover": {
                          backgroundColor: colors.neutrals.gray25,
                        },
                      }}
                      onClick={onLogout}
                    >
                      <LogoutIcon sx={{ width: "12px", mr: "9px" }} />
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: "Hellix",
                        }}
                      >
                        Log out
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              )}
            </Drawer>
          </Box>

          {user && (
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
              }}
            >
              <Link to={ROUTES.about} style={{ display: "flex", textDecoration: "none" }}>
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    mr: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "18px",
                    fontWeight: 500,
                    color: colors.neutrals.gray25,
                    fontFamily: "Inter",
                    textDecoration: "none",
                  }}
                >
                  About
                </Typography>
              </Link>
              <UserShortPannel user={user} />
            </Box>
          )}
        </Toolbar>
      </Container>
      {shouldDisplayFilters && (
        <AppBar
          position="sticky"
          sx={{
            backgroundColor: colors.neutrals.dark150,
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container
            disableGutters
            sx={{
              display: "flex",
              maxheight: "112px",
              width: "100%",
            }}
          >
            <Toolbar
              disableGutters
              sx={{
                width: "100%",
              }}
            >
              <Filter />
            </Toolbar>
          </Container>
        </AppBar>
      )}
    </AppBar>
  );
};
