import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Navbar } from "../../components/Navbar";
import { ROUTES_WITHOUT_NAVBAR } from "../../config/routes";
import { colors } from "../../theme/color";
import { initializeFontFace } from "../../theme/initializeFont";

export const Root: React.FC = () => {
  const { pathname } = useLocation();

  const shouldDisplayNavbar = ROUTES_WITHOUT_NAVBAR.some((route) => route !== pathname);

  useEffect(() => {
    initializeFontFace();
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.neutrals.dark150,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        {shouldDisplayNavbar && <Navbar />}
        <Outlet />
      </Box>
    </Box>
  );
};
