export type User = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  avatar: string;
};

export type Voter = {
  user: User;
};

export enum ParticipantLocation {
  REMOTE = "remote",
  OFFICE = "office",
}

export type Participant = {
  email: string;
  avatar: string;
};

export interface Author {
  id: number;
  firstName: string;
  lastName: string;
  full_name: string;
}
