import { Box, Chip, Skeleton, Typography } from "@mui/material";

import { Tag } from "../../../../api/types/event";
import { colors } from "../../../../theme/color";

export interface TagsListProps {
  list?: Tag[];
  description?: string;
  isLoading?: boolean;
}

const descriptionSkeleton = (amount: number) => {
  return [...Array(amount)].map((_, i) => <Skeleton key={i} animation="wave" variant="text" />);
};
const tagsSkeleton = (amount: number) => {
  return [...Array(amount)].map((_, i) => (
    <Skeleton key={i} variant="rounded" width={70} style={{ height: "100%", borderRadius: 8 }} />
  ));
};

export const TagsList = ({ list = [], description = "", isLoading }: TagsListProps) => {
  return (
    <Box
      sx={{
        padding: "32px",
        backgroundColor: colors.neutrals.dark100,
        borderRadius: "8px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: colors.neutrals.gray100,
            pr: "8px",
            pb: "20px",
          }}
        >
          Tags:
        </Typography>
        {isLoading ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px", height: 24 }}>
            {tagsSkeleton(3)}
          </Box>
        ) : (
          list.map((tag) => <Chip key={tag.id} label={tag.name} />)
        )}
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
          color: colors.neutrals.gray100,
          mb: "8px",
        }}
      >
        Description:
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          color: colors.neutrals.white,
          lineHeight: "140%",
          overflowWrap: "break-word",
        }}
      >
        {isLoading ? descriptionSkeleton(4) : description}
      </Typography>
    </Box>
  );
};
