import { Box } from "@mui/material";
import Modal, { Props as ModalProps } from "react-modal";

import { colors } from "../../theme/color";

type SignInModalProps = ModalProps

export const SignInModal = ({ children, onRequestClose, ...props }: SignInModalProps) => {
  const mainAppElement = document.querySelector("#root") as HTMLElement;

  return (
    <Modal
      {...props}
      className="_"
      overlayClassName="_"
      appElement={mainAppElement}
      onRequestClose={onRequestClose}
      overlayElement={(props, children) => (
        <Box
          {...props}
          sx={{
            position: "fixed",
            inset: 0,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 15,
            background: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(1px)",
          }}
        >
          {children}
        </Box>
      )}
      contentElement={(props, children) => (
        <Box
          {...props}
          sx={{
            position: "absolute",
            outline: "none",
            height: "fit-content",
            width: "fit-content",
            borderRadius: "16px",
            top: 0,
            bottom: 0,
            margin: "auto",
            left: 0,
            right: 0,
            ":focus": {
              boxShadow: "none",
            },
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          {children}
        </Box>
      )}
    >
      <Box
        sx={{
          borderRadius: "8px",
          backgroundColor: colors.neutrals.dark100,
          padding: "30px",
          margin: "30px",
          width: "100%",
          maxWidth: "387px",
          ":focus": {
            boxShadow: "none",
          },
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};
