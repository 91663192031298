import { combineReducers, configureStore, PreloadedState } from "@reduxjs/toolkit";

import { api } from "../api";
import { filtersSlice } from "./slices/filtersSlice";

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  filters: filtersSlice.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([api.middleware]),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
