import { css } from "@emotion/css";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Box, TextField, Typography, useTheme } from "@mui/material";
import { SyntheticEvent } from "react";

import { colors } from "../../theme/color";

interface MultiSelectProps<T> {
  data: T[];
  valueExtractor: (arg: T) => string;
  onChange: (event: SyntheticEvent<Element, Event>, value: T[]) => void;
  label: string;
}

const inputStyling = {
  "& > fieldset": {
    border: `1px solid ${colors.neutrals.gray200}`,
    borderRadius: "6px",
    color: colors.neutrals.white,
  },
};

export const MultiSelect = <T,>({ data, valueExtractor, onChange, label }: MultiSelectProps<T>) => {
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", flex: 2, mr: "20px" }}>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "150%",
          color: colors.neutrals.gray50,
          mb: "10px",
        }}
      >
        {label}
      </Typography>
      <Autocomplete
        multiple
        options={data}
        getOptionLabel={(option) => valueExtractor(option)}
        ChipProps={{
          deleteIcon: (
            <ClearIcon
              sx={{
                width: "16px",
                height: "16px",
                color: colors.neutrals.dark200,
              }}
            />
          ),
        }}
        ListboxProps={{
          style: {
            display: "grid",
            gap: 1,
            maxHeight: "initial",
            flexWrap: "nowrap",
          },
          className: css`
            grid-template-columns: 1fr 1fr 1fr;
            ${theme.breakpoints.down("md")} {
              grid-template-columns: 1fr 1fr;
            }
            ${theme.breakpoints.down("sm")} {
              grid-template-columns: 1fr;
            }
          `,
        }}
        onChange={onChange}
        limitTags={3}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              color: colors.neutrals.white,
              height: "40px",
              ".MuiOutlinedInput-root": {
                flexWrap: "nowrap",
                overflow: "hidden",
                ".MuiSvgIcon-root": {
                  color: colors.neutrals.gray50,
                },
                ":hover": inputStyling,
                ":focus": inputStyling,
                "&.Mui-focused": inputStyling,
                ...inputStyling,
              },
            }}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: true,
              style: {
                color: colors.neutrals.gray25,
              },
            }}
            InputProps={{
              ...params.InputProps,
              type: "search",
              style: {
                color: colors.neutrals.gray25,
              },
            }}
          />
        )}
      />
    </Box>
  );
};
