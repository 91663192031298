import { createTheme } from "@mui/material";

import { colorStyles } from "./color";
import { buttonStyles } from "./components/button";
import { linkStyles } from "./components/link";
import { fontFamily } from "./font";

// Override theme if needed
export const theme = createTheme({
  typography: {
    fontFamily: fontFamily.primary,
  },
  palette: colorStyles,
  components: {
    MuiButton: {
      styleOverrides: buttonStyles,
    },
    MuiLink: {
      styleOverrides: linkStyles,
    },
    MuiTypography: {
      defaultProps: {
        fontFamily: fontFamily.primary,
      },
    },
    MuiContainer: {
      defaultProps: {
        sx: {
          maxWidth: "1200px",
        },
      },
    },
    MuiChip: {
      defaultProps: {
        sx: {
          borderRadius: "6px",
          padding: "2px 6px",
          background:
            "linear-gradient(255.76deg, #42F272 -56.33%, rgba(66, 242, 114, 0) 124.09%), #FFDE00",
          height: "fit-content",
          mr: "4px",
          "& .MuiChip-label": {
            padding: "0",
          },
          "& .MuiChip-deleteIcon": {
            margin: "0",
          },
        },
      },
    },
  },
});
