import { Box } from "@mui/material";
import Modal, { Props as ModalProps } from "react-modal";

type AttachmentModalProps = ModalProps

export const AttachmentModal = ({ children, onRequestClose, ...props }: AttachmentModalProps) => {
  const mainAppElement = document.querySelector("#root") as HTMLElement;

  return (
    <Modal
      {...props}
      className="_"
      overlayClassName="_"
      appElement={mainAppElement}
      onRequestClose={onRequestClose}
      overlayElement={(props, children) => (
        <Box
          {...props}
          sx={{
            position: "fixed",
            inset: 0,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 15,
            backdropFilter: "blur(2px)",
          }}
        >
          {children}
        </Box>
      )}
      contentElement={(props, children) => (
        <Box
          {...props}
          sx={{
            position: "absolute",
            outline: "none",
            height: "fit-content",
            width: "fit-content",
            borderRadius: "16px",
            top: 0,
            bottom: 0,
            margin: "auto",
            left: 0,
            right: 0,
            ":focus": {
              boxShadow: "none",
            },
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          {children}
        </Box>
      )}
    >
      <Box
        sx={{
          borderRadius: "16px",
          backgroundColor: "white",
          border: "1px solid grey",
          padding: "16px",
          ":focus": {
            boxShadow: "none",
          },
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};
