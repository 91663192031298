import { Tag } from "../../api/types/event";
import { User } from "../../api/types/user";

export const cutTags = (tags: Tag[], length: number) => {
  return tags.reduce<{
    sum: number;
    tags: Tag[];
  }>(
    (curr, currTag) => {
      if (curr.sum + currTag.name.length >= length) {
        return curr;
      }
      return {
        sum: curr.sum + currTag.name.length,
        tags: [...curr.tags, currTag],
      };
    },
    {
      sum: 0,
      tags: [],
    },
  ).tags;
};

export const cutSpeaker = (speakers: User[], length: number) => {
  return speakers.reduce<{
    sum: number;
    speakers: User[];
  }>(
    (curr, currTag) => {
      if (curr.sum + currTag.full_name.length >= length) {
        return curr;
      }
      return {
        sum: curr.sum + currTag.full_name.length,
        speakers: [...curr.speakers, currTag],
      };
    },
    {
      sum: 0,
      speakers: [],
    },
  ).speakers;
};

export const cutText = (text: string, length: number) => {
  return text.length > length ? `${text.slice(0, length)}...` : text;
};
