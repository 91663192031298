import { css } from "@emotion/css";
import { Box, Button, Typography } from "@mui/material";
import { useCallback, useState } from "react";

import { useSignOutOfEventMutation, useSignUpForEventMutation } from "../../../../api";
import { ParticipantLocation } from "../../../../api/types/user";
import { SignInModal } from "../../../../components/SignInModal";
import { colors } from "../../../../theme/color";

export interface SignUpButtonProps {
  participating: boolean;
  eventId: string;
}

export const SignUpButton = ({ participating, eventId }: SignUpButtonProps) => {
  const [isSignModalOpen, setIsSignModalOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [signUpForEvent] = useSignUpForEventMutation();
  const [signOutOfEvent] = useSignOutOfEventMutation();

  const onCancel = async () => {
    setIsDisabled(true);
    await signOut();
    setIsDisabled(false);
  };

  const signUp = useCallback(
    async (location: ParticipantLocation) => {
      await signUpForEvent({
        id: eventId,
        request: {
          location,
        },
      }).unwrap();
    },
    [signUpForEvent, eventId],
  );

  const signOut = useCallback(async () => {
    await signOutOfEvent({
      id: eventId,
    }).unwrap();
  }, [signOutOfEvent, eventId]);

  const handleSignUp = useCallback(
    async (location: ParticipantLocation) => {
      await signUp(location);
      setIsSignModalOpen(false);
    },
    [signUp],
  );

  const handleClick = () => {
    if (participating) return onCancel();
    setIsSignModalOpen(true);
  };

  return (
    <>
      <Button
        type="submit"
        variant="contained"
        sx={{
          backgroundColor: colors.primary.green80,
          color: "white",
          padding: "10px 32px",
          width: "fit-content",
          mr: "10px",
          mb: "20px",
          whiteSpace: "nowrap",
        }}
        className={css`
          &:hover {
            background-color: ${colors.primary.green80};
            color: white;
          }
          &:disabled {
            background-color: ${colors.neutrals.dark100};
            p {
              color: white;
            }
          }
          p {
            margin-bottom: 0;
          }
        `}
        onClick={handleClick}
        disabled={isDisabled}
      >
        <Typography
          sx={{
            fontFamily: "Hellix",
            fontSize: "18px",
            color: colors.neutrals.dark100,
            mb: "20px",
          }}
        >
          {participating ? "Cancel" : "Sign Up"}
        </Typography>
      </Button>
      {/* Modal */}
      <SignInModal isOpen={isSignModalOpen} onRequestClose={() => setIsSignModalOpen(false)}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            component="h2"
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: colors.neutrals.white,
              mb: "15px",
            }}
          >
            How will you join us?
          </Typography>
          <Typography
            component="h2"
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: colors.neutrals.gray100,
              marginBottom: "25px",
            }}
          >
            We are glad that you’ve decided to attend our event! Please, let us know if you would
            like to join us in the office or remotely.
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: colors.neutrals.gray25,
                color: colors.neutrals.black,
                padding: "10px 32px",
                width: "fit-content",
                ":hover": {
                  backgroundColor: colors.neutrals.gray25,
                  color: colors.neutrals.black,
                },
              }}
              onClick={() => handleSignUp(ParticipantLocation.OFFICE)}
            >
              OFFICE
            </Button>

            <Button
              variant="contained"
              sx={{
                backgroundColor: colors.neutrals.gray25,
                color: colors.neutrals.black,
                padding: "10px 32px",
                width: "fit-content",
                ":hover": {
                  backgroundColor: colors.neutrals.gray25,
                  color: colors.neutrals.black,
                },
              }}
              onClick={() => handleSignUp(ParticipantLocation.REMOTE)}
            >
              REMOTE
            </Button>
          </Box>
        </Box>
      </SignInModal>
    </>
  );
};
