import { GlobalStylesProps } from "@mui/material";

import { colors } from "./color";
import { fontFamily } from "./font";

export const globalStyles: GlobalStylesProps = {
  styles: {
    body: { backgroundColor: colors.neutrals.dark150 },
    fontFamily: fontFamily.primary,
  },
};
