import { PaletteOptions } from "@mui/material";

export const colors = {
  primary: {
    green100: "#83EA34",
    green80: "#9CEE5D",
    green60: "#B5F285",
    green50: "#C1F499",
    green40: "#CDF7AE",
    green30: "#DAF9C2",
    green20: "#E6FBD6",
    green10: "#F3FDEB",
  },
  accent: {
    teal200: "#34EABF",
    blue100: "#34A7EA",
    blue200: "#343AEA",
    purple200: "#9B34EA",
    pink200: "#EA34CC",
    red200: "#EA345F",
    orange200: "#EA7734",
    yellow200: "#EAE434",
    teal10: "#EBFDF9",
    blue10: "#EBFDF9",
    blue20: "#EBEBFD",
    purple10: "#F5EBFD",
    pink10: "#FDEBFA",
    red10: "#FDEBEF",
    orange10: "#FDF1EB",
    yellow10: "#FDFCEB",
  },
  neutrals: {
    dark300: "#111111",
    dark200: "#1A1D23",
    dark150: "#21252C",
    dark100: "#2B303B",
    gray200: "#69768F",
    gray100: "#AAB9D4",
    gray50: "#C2D0EA",
    gray25: "#E3EAF6",
    black: "#000000",
    white: "#FFFFFF",
  },
  notifications: {
    success: "#339989",
    warning: "#F9A825",
    error: "#F2542D",
  },
};

export const colorStyles: PaletteOptions = {
  background: {
    default: colors.neutrals.dark150,
  },
  primary: {
    main: colors.primary.green100,
  },
  secondary: {
    main: colors.neutrals.dark100,
  },
};
